<template>
  <section class="blogs-block pb-5">
    <div class="mb-4">
      <CContainer>
        <CRow class="align-items-end">
          <CCol md="9">
            <CRow>
              <CCol md="6">
                <section-title
                  tagline="FROM OUR BLOG"
                  :title="title"
                  :description="description"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="3" class="text-end">
            <router-link to="/blogs">View All Blogs</router-link>
          </CCol>
        </CRow>
      </CContainer>
    </div>
    <CContainer>
      <CRow>
        <CCol md="4" v-for="blog in blogs" :key="blog.id">
          <article-card :article="blog" />
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>

<script>
import SectionTitle from "@/components/shared-components/section-title/SectionTitle.vue";
import ArticleCard from "@/components/shared-components/article-card/ArticleCard.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: { SectionTitle, ArticleCard },
  name: "BlogsBlock",
  props: ["title", "subtitle", "description"],

  data() {
    return {
      blogs: ref([]),
    };
  },

  mounted() {
    this.getBlogDetail();
  },

  methods: {
    async getBlogDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-feature-blog";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let blogList = response.data.data;
          if (response.data.status_code === 200 && blogList.length > 0) {
            Object.entries(blogList).forEach(([key, value]) => {
              this.blogs.push({
                id: value.blog_id,
                category: value.blog_category_name,
                date: value.blog_publish_date,
                title: value.title,
                author: value.blog_created_by,
                readTime: value.blog_read_time,
                image: value.blog_image_path,
                authorImage: value.blog_creator_image_path,
              });
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
