<template>
  <div class="cover-loader" v-if="loader">
    <div class="text-center">
      <div><img src="/img/logo.png" /></div>
      <div class="mt-4"><CSpinner color="danger" /></div>
    </div>
  </div>

  <business-home-slider />

  <!-- positioning starts -->

  <div v-for="section in homeSections" class="mb-5">
    <div
      v-if="section.section === 'category_section' && section.show"
      class="hp-product-categories"
    >
      <category-tiles />
    </div>
    <div v-if="section.section === 'rider_story_section'">
      <rider-stories
        :title="aboutUsDetail.rider_story_title"
        :subtitle="aboutUsDetail.rider_story_sub_title"
        :description="aboutUsDetail.rider_story_description"
        :image="aboutUsDetail.rider_story_image"
      />
    </div>
    <div v-if="section.section === 'who_we_are_video_section' && section.show">
      <home-video
        :bannerTitle="aboutUsDetail.who_we_are_title"
        :bannerSubTitle="aboutUsDetail.who_we_are_sub_title"
        :bannerDescription="aboutUsDetail.who_we_are_description"
        :bannerImage="aboutUsDetail.who_we_are_image"
        :bannerVideo="bannerVideo"
      />
    </div>
        
    <div
      v-if="section.section === 'new_arrival_section' && section.show"
      class="hp-featured-items"
    >
      <section>
        <new-arrival-carousel
          sectionTitle="New Arrivals"
          subTitle="own it now, up to 6 months interest free!"
          prevEl="naPrevArrow"
          nextEl="naNextArrow"
        />
      </section>
    </div>
    <br/>

    <div
      v-if="section.section === 'featured_item_section' && section.show"
      class="hp-featured-items"
    >
      <section>
        <item-carousel
          sectionTitle="Featured Items"
          subTitle="own it now, up to 6 months interest free!"
          prevEl="featuredPrevArrow"
          nextEl="featuredNextArrow"
        />
      </section>
    </div>

    <div
      v-if="
        section.section === 'ultimate_tagline_section_flash_sale' &&
        section.show
      "
    >
      <flash-sale />
    </div>

    <div v-if="section.section === 'service_slider_section'">
      <who-we-are />
    </div>
  </div>

  <!-- positioning ends -->

  <blogs-block
    :title="blog_title"
    :subtitle="blog_sub_title"
    :description="blog_description"
  />

  <section class="pt-5">
    <testimonials
      :title="aboutUsDetail.testimonial_title"
      :subtitle="aboutUsDetail.testimonial_sub_title"
      :description="aboutUsDetail.testimonial_description"
    />
  </section>

  <section class="pt-5 hp-brands">
    <brands />
  </section>

  <newsletter-sub />

  <CModal
    :visible="itemPopupVisible"
    size="lg"
    scrollable="true"
    @close="
      this.$store.dispatch('itemPopup/popupHandler', {
        popupVisible: false,
      })
    "
  >
    <CModalHeader>
      <CModalTitle>Product name</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <item-popup :item="adData" />
    </CModalBody>
  </CModal>
</template>

<script>
import AppLayout from "@/layouts/EcommerceLayout.vue";
import BusinessHomeSlider from "@/containers/business/hero-slider/BusinessHomeSlider";
import CategoryTiles from "@/containers/business/category-tiles/CategoryTiles.vue";
import FlashSale from "@/containers/business/flash-sale/FlashSale.vue";
import HomeVideo from "@/containers/business/home-video/HomeVideo.vue";
import WhoWeAre from "@/containers/business/who-we-are/WhoWeAre.vue";
import RiderStories from "@/containers/business/riders-stories/RiderStories.vue";
import BlogsBlock from "@/containers/business/blogs-block/BlogsBlock.vue";
import Testimonials from "@/containers/business/testimonials/Testimonials.vue";
import Brands from "@/containers/business/brands/Brands.vue";
import NewsletterSub from "@/containers/business/newsletter-sub/NewsletterSub.vue";
import AppFooter from "@/components/layout-components/app-footer/AppFooter.vue";
import ItemCarousel from "@/containers/e-commerce/item-carousel/ItemCarousel.vue";
import NewArrivalCarousel from "@/containers/e-commerce/item-carousel/NewArrivalCarousel.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { ref } from "vue";
import { mapState } from "vuex";
import ItemPopup from "@/components/shared-components/item-poup/ItemPopup.vue";
import { useToast } from "vue-toastification";
import { helper } from "@/helper/Helper";

const toast = useToast();

export default {
  components: {
    AppLayout,
    BusinessHomeSlider,
    CategoryTiles,
    FlashSale,
    HomeVideo,
    WhoWeAre,
    RiderStories,
    BlogsBlock,
    Testimonials,
    Brands,
    NewsletterSub,
    AppFooter,
    ItemCarousel,
    ItemPopup,
    NewArrivalCarousel
  },

  name: "Home",

  data() {
    return {
      aboutUsDetail: ref(""),
      bannerVideo: ref(""),
      homeSections: [],
      loader: true,
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    let helperObj = new helper();
    helperObj.resetLocalStorageProductDataFilter();
    this.getAboutUsDetail();
    this.getHomePageSectionComponentDetail()
      .then((response) => {
        this.homeSections = response.data.data;
      })
      .catch((error) =>
        toast.error(error.response.data.message, {
          timeout: 2000,
        })
      );
  },

  methods: {
    setPosition() {},

    async getAboutUsDetail() {
      this.loader = true;
      const url = UrlConfig.urlHelper.hostUrl + "/get-about-us-detail";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          this.loader = false;
          let aboutUsDetail = response.data.data;
          if (response.data.status_code === 200 && aboutUsDetail !== "") {
            this.aboutUsDetail = aboutUsDetail;
            this.bannerVideo =
              "https://www.youtube.com/embed/" +
              aboutUsDetail.who_we_are_youtube;
          }
        })
        .catch((error) => {
          this.loader = false;
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },

    async getHomePageSectionComponentDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-home-section-position";
      const header = UrlConfig.unAuthorizedUrlHeader;
      return await axios.get(url, header);
    },

    popupControl(option) {
      this.itemPopupVisible = option.visible;
      this.selectedItem = option.item;
    },
  },

  computed: {
    ...mapState({
      itemPopupVisible: (state) => state.itemPopup.itemPopupVisible,
    }),
  },
};
</script>
